import React from "react";
import Modal from "react-bootstrap/Modal";

import { BigNumber } from "bignumber.js";
import Switch from "react-switch";

import Div from "../layouts/Div";

import Backend from "../../../utils/Backend";
import AuthManager from "../../../utils/AuthManager";
import Website from "../../../utils/Website";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

import ImageEdit from "../common/ImageEdit";
import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

import ColorPicker from "../ColorPicker";

import ImagePickerModal from "./ImagePickerModal";
import SwatchColorPicker from "../common/SwatchColorPicker";

const LOADING_GIF =
  Math.random() < 0.5
    ? require("../../../assets/media/gifs/creating-website.gif")
    : require("../../../assets/media/gifs/creating-website.gif");

const TYPE_OPTIONS = [
  {
    label: "Live",
    value: false,
  },
  {
    label: "Demo",
    value: true,
  },
];

const MAX_FILE_SIZE_OPTIONS = [
  {
    label: "5MB (Small PDF/Document)",
    value: 5000000,
  },
  {
    label: "10MB (Large PDF/Document)",
    value: 10000000,
  },
  {
    label: "25MB (High Resolution Photo)",
    value: 25000000,
  },
  {
    label: "50MB (Large File)",
    value: 50000000,
  },
];

export default class EditWebsiteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this._getState(props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      show: props.show,
      website: props.website,
      partner: props.partner,
    };
  }

  _handleChange(e) {
    let website = this.state.website;
    website[e.target.name] = e.target.value;
    this.setState({ website });
  }

  _isFormValid() {
    let { website } = this.state;

    let { demo, name, errorSlug } = website;

    let error = null;
    if (demo == null) {
      error = "Please select a type";
    } else if (!name || name === "") {
      error = "Please enter a valid name";
    } else if (errorSlug) {
      error = "Please enter a valid xyz url";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _checkSlug() {
    let { website } = this.state;

    website.newSlug = website.newSlug || website.slug;
    Backend.slugify(website.newSlug)
      .then((response) => {
        if (
          response.slug !== website.newSlug &&
          website.newSlug !== website.slug
        ) {
          this.setState({ errorSlug: true });
        } else {
          this.setState({ website, errorSlug: false });
        }
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _onEditClicked() {
    let { website } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ loading: true });
    Backend.updateWebsite(website)
      .then((website) => {
        this.setState({
          website: website,
          edited: true,
          loading: false,
        });
        this.props.onUpdated(website);
        Notify.success("Website successfully edited!");
      })
      .catch((error) => {
        this.setState({ loading: false });
        Notify.error(error.message);
      });
  }

  _onViewWebsiteClicked(website) {
    this.props.onCancel();
    this.setState({ loadingHash: true });
    let url = Website.getSiteUrl(website) + "?edit=true";
    let viewWebsiteTab = window.open();
    Backend.createLoginHash(website)
      .then((hash) => {
        viewWebsiteTab.location.href = url + "&h=" + hash.value;
        this.setState({ loadingHash: false, edited: false });
      })
      .catch((error) => {
        viewWebsiteTab.location.href = url;
        this.setState({ loadingHash: false, edited: false });
      });
  }

  _renderContent() {
    let { loading, edited } = this.state;

    // if(edited){
    //   return this._renderEdited()
    // }
    // else{
    return this._renderForm();
    // }
  }

  _renderLoading() {
    return (
      <Modal.Body>
        <div className="text-center my-auto">
          <img src={LOADING_GIF} alt="Laoding" height="300px" />
        </div>
      </Modal.Body>
    );
  }

  _renderEdited() {
    let { website } = this.state;
    return (
      <>
        <Modal.Body>
          <div className="text-center my-auto">
            <h1>Success!</h1>
            <p>The website was successfully edited</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              this.props.onCancel();
              this.setState({
                edited: false,
              });
            }}
          >
            Close
          </button>

          <a
            href="#"
            className={`btn btn-primary`}
            onClick={(e) => {
              e.preventDefault();
              this._onViewWebsiteClicked(website);
            }}
          >
            View
          </a>
        </Modal.Footer>
      </>
    );
  }

  _renderForm() {
    let {
      loading,
      website,
      partner,
      errorSlug,
      imageLoading,
      target_website_id,
    } = this.state;

    let className = "input-group-append-bg-dark ";

    if (website.slug) {
      className += errorSlug
        ? "input-group-append-bg-dark-error"
        : "input-group-append-bg-green";
    }

    let image = website.image;

    return (
      <>
        <Modal.Body>
          <Div disabled={loading} spinner={true}>
            {!partner && (
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Type</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={TYPE_OPTIONS.find(
                      (option) => option.value === website.demo
                    )}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    onChange={(option) => {
                      console.log("+++", option);
                      website.demo = option.value;
                      this.setState({ website });
                    }}
                    options={TYPE_OPTIONS}
                  />
                </div>
              </div>
            )}

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Site Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-solid"
                  value={website.name}
                  onChange={(e) => {
                    website.name = e.target.value;
                    this.setState({ website });
                  }}
                  onBlur={(e) => {
                    if (!website.slug) {
                      this._setSlug();
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                {window.General.Branding.Name} URL
              </label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <input
                    type="text"
                    name="newSlug"
                    className="form-control form-control-solid"
                    value={website.newSlug || website.slug}
                    onChange={(e) => this._handleChange(e)}
                    onBlur={(e) => {
                      this._checkSlug();
                    }}
                  />
                  <div class={`input-group-append ${className}`}>
                    <span class="input-group-text">
                      .{window.General.Branding.Domain}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Description</label>
              <div className="col-lg-9 my-auto">
                <textarea
                  name="description"
                  className="form-control form-control-solid"
                  value={website.description}
                  placeholder="Description goes here..."
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Keywords</label>
              <div className="col-lg-9 my-auto">
                <textarea
                  name="keywords"
                  className="form-control form-control-solid"
                  value={website.keywords}
                  placeholder="Comma separated list."
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                Google Tag Manager ID
              </label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="gtm_id"
                  className="form-control form-control-solid"
                  value={website.gtm_id}
                  placeholder="GTM-12345"
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                Max Number Of File Uploads
              </label>
              <div className="col-lg-9 my-auto">
                <input
                  type="number"
                  name="number_of_files"
                  className="form-control form-control-solid"
                  value={website.max_no_of_uploads}
                  placeholder="Number Of Files"
                  onChange={(e) => {
                    website.max_no_of_uploads = Math.abs(e.target.value);

                    this.setState({ website });
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                Max Size Of A File Upload
              </label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={MAX_FILE_SIZE_OPTIONS.find(
                    (option) => option.value === website.max_upload_size
                  )}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  onChange={(option) => {
                    console.log("+++", option);
                    website.max_upload_size = option.value;
                    this.setState({ website });
                  }}
                  options={MAX_FILE_SIZE_OPTIONS}
                />
              </div>
            </div>

            <div class="c-separator form-group row mt-4 align-items-center text-center">
              <div class="col-3">
                <hr />
              </div>
              <div class="col-6">
                <span>
                  <strong>Financials</strong>
                </span>
              </div>
              <div class="col-3">
                <hr />
              </div>
            </div>

            {partner && (
              <>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Monthly Licence
                  </label>
                  <div className="col-lg-9 my-auto">
                    <input
                      type="number"
                      step={"0.01"}
                      name="recurring"
                      className="form-control form-control-solid"
                      value={
                        partner.monthly_license
                          ? partner.monthly_license / 100
                          : ""
                      }
                      placeholder="€0.00"
                      onChange={(e) => {
                        partner.monthly_license = Math.round(
                          e.target.value * 100
                        );
                        website.monthly_license = partner.monthly_license;

                        this.setState({ website, partner });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    Management & Running Fee (%)
                  </label>
                  <div className="col-lg-9 my-auto">
                    <input
                      type="text"
                      name="commission"
                      className="form-control form-control-solid"
                      defaultValue={
                        partner.commission
                          ? BigNumber(partner.commission).multipliedBy(100)
                          : ""
                      }
                      placeholder="0%"
                      onChange={(e) => {
                        partner.commission = +(e.target.value / 100.0);
                        partner.commission =
                          Math.round(
                            (partner.commission + Number.EPSILON) * 10000
                          ) / 10000;
                        website.commission = partner.commission;

                        this.setState({ website, partner });
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {!partner && (
              <>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Recurring</label>
                  <div className="col-lg-9 my-auto">
                    <input
                      type="number"
                      step={"0.01"}
                      name="recurring"
                      className="form-control form-control-solid"
                      value={website.recurring / 100}
                      placeholder="€0.00"
                      onChange={(e) => {
                        website.recurring = Math.round(e.target.value * 100);
                        this.setState({ website });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Once Off</label>
                  <div className="col-lg-9 my-auto">
                    <input
                      type="number"
                      step={"0.01"}
                      name="once_off"
                      className="form-control form-control-solid"
                      value={website.once_off / 100}
                      placeholder="€0.00"
                      onChange={(e) => {
                        website.once_off = Math.round(e.target.value * 100);
                        this.setState({ website });
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="c-separator form-group row mt-4 align-items-center text-center">
              <div className="col-3">
                <hr/>
              </div>
              <div className="col-6">
                <span>
                  <strong>Notice</strong>
                </span>
              </div>
              <div className="col-3">
                <hr/>
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label className="col-lg-3 col-form-label">Active</label>
              <div className="col-lg-3">
                <Switch
                  onChange={checked => {
                    if(!website.notice){
                      website.notice = {}
                    }
                    website.notice.active = checked
                    this.setState({ website })
                  }}
                  checked={website.notice?.active}
                  aria-labelledby="neat-label"
                  onColor="#333333"
                  offColor="#F3F6F9"
                  checkedIcon={null}
                  uncheckedIcon={null}
                  className="c-switch"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Text</label>
              <div className="col-lg-9 my-auto">
                <textarea
                  name="keywords"
                  className="form-control form-control-solid"
                  value={website.notice?.text}
                  placeholder="Website Under Maintenance"
                  onChange={(e) => {
                    if(!website.notice){
                      website.notice = {}
                    }
                    website.notice.text = e.target.value
                    this.setState({website})
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Text Colour</label>
              <div className="col-lg-9 my-auto">
                <SwatchColorPicker
                  color={ website.notice?.text_color || '#FFF' }
                  onColorChange={(color) => {
                    if(!website.notice){
                      website.notice = {}
                    }
                    website.notice.text_color = color.hex
                    this.setState({ website })
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Background Colour</label>
              <div className="col-lg-9 my-auto">
                <SwatchColorPicker
                  color={ website.notice?.background_color || website.primary_color }
                  onColorChange={(color) => {
                    if(!website.notice){
                      website.notice = {}
                    }
                    website.notice.background_color = color.hex
                    this.setState({ website })
                  }}
                />
              </div>
            </div>

            <div class="c-separator form-group row mt-4 align-items-center text-center">
              <div class="col-3">
                <hr />
              </div>
              <div class="col-6">
                <span>
                  <strong>Branding</strong>
                </span>
              </div>
              <div class="col-3">
                <hr />
              </div>
            </div>

            <ColorPicker
              title={partner ? "Brand Colour" : "Main Site Colour"}
              color={website.primary_color}
              onColorSelected={(color) => {
                website.primary_color = color;
                this.setState({ website });
              }}
            />

            <div className="form-group mt-5">
              <label>Logo</label>
              <div className="mt-3">
                <ImageEdit
                  image={partner ? partner.company.logo : website.company?.logo}
                  type="photo"
                  hidePreview={false}
                  aspectRatio={1.5}
                  onUpdated={(image) => {
                    if (partner) {
                      partner.company.logo = image;
                    } else if (website.company) {
                      website.company.logo = image;
                    }
                    website.company_logo_id = image.id;
                    this.setState({ partner, website });
                  }}
                />
              </div>
            </div>

            <div class="form-group">
              <label>Favicon</label>
              <div className="mt-3">
                <ImageEdit
                  image={website.favicon}
                  type="photo"
                  cropImage={true}
                  hidePreview={false}
                  aspectRatio={1}
                  onUpdated={(image) => {
                    website.favicon = image;
                    this.setState({ website });
                  }}
                />
              </div>
            </div>
          </Div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._onEditClicked()}
          >
            Save
          </button>
        </Modal.Footer>
      </>
    );
  }

  render() {
    let { show, edited, loading, website, partner, showImagePicker } =
      this.state;

    if (!website) {
      return null;
    }

    let type = partner ? "Partner" : "Site";

    let title = loading ? `Editing ${type}...` : `Edit ${type}`;
    if (edited) {
      title = `${type} Edited`;
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.props.onCancel();
            this.setState({
              edited: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          {this._renderContent()}
        </Modal>
        <ImagePickerModal
          show={showImagePicker}
          onSelected={(image) => {
            this.setState({ imageLoading: true, showImagePicker: false });
            Backend.addImage(image)
              .then((image) => {
                website.image = image;
                website.banner_image_id = image.id;
                this.setState({ website, imageLoading: false });
              })
              .catch((error) => {
                Notify.error(error.message);
                this.setState({ imageLoading: false });
              });
          }}
          onHide={() => this.setState({ showImagePicker: false })}
        />
      </>
    );
  }
}

EditWebsiteModal.defaultProps = {
  loading: false,
};
